import React from 'react'
import Navbar from '../components/Navbar'
import MadamBook from '../img/madambook.png'
import MadamBookUS from '../img/coverus.png'
import RuinsBookUK from '../img/ruinsuk.png'
import RuinsBookUS from '../img/ruinsus.png'
import Layout from '../components/Layout'
import '../styles/books.sass'
// import Squiggle from '../img/squiggly2.png'
// import BuySection from '../components/BuyNow'
// import Signature from '../img/signature.png'
import Footer from '../components/Footer'

export const ProductPageTemplate = () => (
  <>
    <div>
      <Navbar showSub={false} showMenu={true} bold />
      <div className="container books" style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        padding: '2rem 0',
      }}>
        <div className='book-wrapper'>
          <a
            href="/books/madam"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexWrap: 'wrap',
              color: '#000',
              flexDirection: 'column'
            }}
          >
            <h1 className="text-center" style={{
              paddingBottom: '20px'
            }}>Madam</h1>
            <img className="madam-book-img book-first-image" src={MadamBook}></img>
            <img className="madam-book-img book-second-image" src={MadamBookUS}></img>
          </a>
        </div>

        <div className='book-wrapper'>
          <a
            href="/books/the-ruins"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexWrap: 'wrap',
              color: '#000',
              flexDirection: 'column'
            }}
          >
            <h1 className="text-center" style={{
              paddingBottom: '20px'
            }}>The Ruins</h1>
            <img className="madam-book-img book-first-image" src={RuinsBookUK}></img>
            <img className="madam-book-img book-second-image" src={RuinsBookUS}></img>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

const ProductPage = () => {
  return (
    <Layout>
      <ProductPageTemplate />
    </Layout>
  )
}

export default ProductPage
